<template>
  <div>
    <v-app-bar dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="30">
              <img :src="user.photoURL" :alt="user.displayName" />
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer app dark v-model="drawer" >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            LeftBrain Apps
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item link v-for="item in privateNavItems" :key="item.name" :to="{name: item.name}">
          <v-list-item-icon>
            <v-icon>{{item.meta.navIcon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{item.meta.navName}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { auth } from "../firebase";
import {privateNavItems} from "../router"

export default {
  props: ["title", "user"],
  data() {
    return {
      drawer: false
    };
  },
  computed: {
    privateNavItems() {
      return privateNavItems
    }
  },
  methods: {
    logout() {
      auth().signOut();
    }
  }
};
</script>
